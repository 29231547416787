<template>
  <span :class="cssClass">{{text}}</span>
</template>
<script>
export default {
  name: 'TicketStatus',
  props: ['ticket'],
  computed: {
    cssClass() {
      return {
        'Recoding': 'text-info',
        'Accepted': 'text-success',
        'Canceled': 'text-danger',
        'Refund': 'text-warning',
        'Processing': 'text-info',
        'Completed': 'text-primary'
      }[this.ticket.status]
    },
    text() {
      return {
        'Recoding': 'กำลังบันทึกเลข',
        'Accepted': 'รับแทง',
        'Canceled': 'ยกเลิก',
        'Refund': 'คืนเงิน',
        'Processing': 'กำลังออกผล',
        'Completed': 'จ่ายเงินแล้ว'
      }[this.ticket.status]
    }
  }
}
</script>
