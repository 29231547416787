<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>รายการจ่ายเงิน</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />
      <div v-if="isAvailable" class="card-body p-0">
        <table class="table table-sm table-bordered table-striped table-hover mb-0">
          <thead>
            <tr>
              <th width="60">ลำดับ</th>
              <th width="200">วันที่</th>
              <th width="15%">สมาชิก</th>
              <th width="15%">ยอดแทง</th>
              <th width="15%">ถูกรางวัล</th>
              <th>หมายเหตุ</th>
              <th width="15%">สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-center">{{ item.createdAt | dispDateTime("YYYY-MM-DD HH:mm:ss") }}</td>
              <td class="text-center">{{item.account.mainUser.username}}</td>
              <td class="text-right">{{item.summary.amount | amountFormat(2)}}</td>
              <td class="text-right text-success">{{item.summary.won | amountFormat(2)}}</td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-center"><TicketStatus :ticket="item" /></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="3" class="text-center">รวม</th>
              <th class="text-right">{{ summary.amount | amountFormat(2) }}</th>
              <th class="text-right text-success">{{ summary.won | amountFormat(2) }}</th>
              <th colspan="2" class="text-center">
                <button v-if="!isPaid" class="btn btn-success btn-block" @click="payTickets"><i class="fas fa-hand-holding-usd"></i> จ่ายเงิน</button>
                <button v-else class="btn btn-danger btn-block" @click="cancelPaid"><i class="fas fa-undo-alt"></i> ยกเลิกจ่ายเงิน</button>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else class="card-body">
        <div class="alert alert-warning mb-0">ยังไม่ได้ออกผล</div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import RoundCardHeader from './components/RoundCardHeader'
import { marketOpenBets } from "@/configs/market.config"
import PayHalfNumberList from './components/PayHalfNumberList'
import cryptoRandomString from 'crypto-random-string'
import TicketStatus from '@/views/components/TicketStatus'

export default {
  name: 'PayHalfNumbers',
  components: {
    RoundCardHeader,
    PayHalfNumberList,
    TicketStatus
  },
  data() {
    return {
      data: null,
      uniqueCode: null
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    round() {
      return this.data?.round || {}
    },
    items() {
      return this.data?.tickets || []
    },
    status() {
      return this.round?.status
    },
    isAvailable() {
      return ['Complete', 'Paid'].includes(this.status)
    },
    isPaid() {
      return this.status === 'Paid'
    },
    summary() {
      return this.items.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.won += item.summary.won
        return total
      }, {
        amount: 0,
        won: 0
      })
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.getTickets()
      }
    }
  },
  methods: {
    getTickets() {
      LottoService.getWonTickets(this.roundId).then((response)=>{
        if(response.success) {
          this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    payTickets() {
      Swal.fire({
        title: 'ยืนยันจ่ายเงิน!',
        text: "ยอดจะถูกโอนเข้าบัญชีสมาชิก",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.payWonTickets(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'จ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'จ่ายเงินสำเร็จ',
            'success'
          )
          .then(()=>{
            this.getTickets()
          })
        }
      })
    },
    cancelPaid() {
      Swal.fire({
        title: 'ยกเลิกการจ่ายเงิน!',
        text: "ยอดที่โอนเข้าบัญชีสมาชิก จะถูกดึงกลับ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.cancelPayWonTickets(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ยกเลิกจ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ยกเลิกจ่ายเงินสำเร็จ',
            'success'
          )
          .then(()=>{
            this.getTickets()
          })
        }
      })
    }
  },
  mounted() {
    this.getTickets()
  }
}
</script>
<style lang="scss" scoped>
.table {
  border: 0;

  thead {
    tr {
      th {
        font-size: 90%;
        text-align: center;
      }
    }
  }

  tr {
    th {
      border-top: 0;
      vertical-align: middle;
    }

    td {
      vertical-align: middle;
      font-size: 90%;
    }
  }

  tr {
    td:first-child,
    th:first-child {
      border-left: 0;
    }
    td:last-child,
    th:last-child {
      border-right: 0;
    }
  }
}
</style>
